/* You can add global styles to this file, and also import other style files */

@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

.MINI {
  button {
    background: #2a6480;

    &:hover:enabled {
      background: #4b9abe;
    }
  }
  .logo {
    height: 35px;
  }
}

header {
  background: black;
  .logo {
    height: 15px;
  }
}
